const actions = {
  SET_STATE: 'debitCards/SET_STATE',
  FETCH_ALL_DEBIT_CARDS: 'debitCards/FETCH_ALL_DEBIT_CARDS',
  FETCH_ALL_DEBIT_CARDS_DETAILS: 'debitCards/FETCH_ALL_DEBIT_CARDS_DETAILS',
  FETCH_ALL_WALLET_TRANSACTION: 'debitCards/FETCH_ALL_WALLET_TRANSACTION',
  CHANGE_DEBIT_CARD_STATUS: 'debitCards/CHANGE_DEBIT_CARD_STATUS',
  CHANGE_DEBIT_CARD_WALLET_STATUS: 'debitCards/CHANGE_DEBIT_CARD_WALLET_STATUS',
  REPLACE_DEBIT_CARD: 'debitCards/REPLACE_DEBIT_CARD',
  DELETE_DEBIT_CARD_WALLET: 'debitCards/DELETE_DEBIT_CARD_WALLET',
  CHANGE_BULK_DEBIT_CARD_STATUS: 'debitCards/CHANGE_BULK_DEBIT_CARD_STATUS',
}

export default actions

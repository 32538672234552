import actions from './actions'

const initialState = {
  details: {
    loading: true,
  },
  businesses: {
    loading: true,
  },
  businessNote: null,
}

export default function businessReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, businesses: { ...action.payload } }

    case actions.SET_STATE_DETAIL: {
      return {
        ...state,
        details: { ...action.payload },
        businessNote: action.payload.data && action.payload.data.business.notes,
      }
    }
    case actions.LIST_BUSINESS_NOTES: {
      return {
        ...state,
        businessNote: action.payload.notes,
      }
    }
    default:
      return state
  }
}

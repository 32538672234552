import actions from './actions'

const initialState = {
  allUsers: {
    loading: true,
  },
  user: {
    loading: true,
  },
  userNote: null,
  userToken: {
    loading: true,
    token: null,
  },
}

export default function allUsersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      return {
        ...state,
        ...action.payload,
        userNote:
          action.payload.user &&
          action.payload.user.data.user &&
          action.payload.user.data.user.notes,
      }
    }
    case actions.LIST_USERS_NOTES: {
      return {
        ...state,
        userNote: action.payload.notes,
      }
    }
    default:
      return state
  }
}
